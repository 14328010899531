<template>
  <div class="view-box">
    <van-sticky>
      <title-bar2 :title="documentName" />
    </van-sticky>
    <div class="document-box">
      <document-viewer ref="viewer" class="document-viewer" :loading="loading" :percentage="percentage"></document-viewer>
    </div>
    <div class="bar-box">
      <van-goods-action id="bottomBar">
        <van-goods-action-icon icon="wap-home-o" text="首页" @click="onHome" />
        <van-goods-action-icon icon="chat-o" text="客服" @click="onRegist" />
        <van-goods-action-button type="danger" text="打印" v-if="unlocked" @click="onPrint" />
        <van-goods-action-button type="warning" text="下载" v-if="unlocked && documentType == 2" @click="onDownload" />
        <van-goods-action-button type="danger" text="付费阅读" v-if="!unlocked" @click="onPay" />
        <van-goods-action-button type="warning" text="联系商家阅读" v-if="!unlocked" @click="onRegist" />
      </van-goods-action>
    </div>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import DocumentViewer from "@/components/MT/DocumentViewer";
import Tools from "@/tool";
import Config from "@/api/viewer";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
    DocumentViewer,
  },
  data() {
    return {
      loading: true,
      percentage: 0,
      unlocked: null,
      documentGuid: null,
      documentName: null,
      documentType: null,
    };
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.documentGuid = this.$route.query.documentGuid;
      if (this.$route.query.token) {
        this.$store.commit("setToken", this.$route.query.token);
        this.$router.replace({
          name: "Viewer",
          query: {
            documentGuid: this.$route.query.documentGuid
          }
        });
      }
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(false);
      }
    },
    //获取是否购买文档
    getDocumentPurchase() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.purchased, model)
        .then((res) => {
          this.unlocked = res.data.Data.IsPurchased;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //查询资料信息
    getDocumentInfo() {
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.info, model)
        .then((res) => {
          var data = res.data.Data;
          this.documentName = data.DocumentName;
          this.documentType = data.DocumentType;
          this.universityCode = data.UniversityCode;
          this.majorCode = data.MajorCode;
          this.subjectCode = data.SubjectCode;
          window.document.title = data.DocumentName;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //获取文档密码
    getDocumentPassword() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      var model = {
        DocumentGuid: this.documentGuid,
      };
      return this.$axios
        .post(Config.document.password, model)
        .then((res) => {
          this.documentPassword = res.data.Data.Password;
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        });
    },
    //下载文档
    getDocumentUrl() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      this.loading = true;
      //下载文档
      var model = {
        DocumentGuid: this.documentGuid,
        ProxyCode: this.$store.state.proxyCode,
      };
      return this.$axios
        ({
          method: "get",
          url: Config.document.download,
          params: model,
          responseType: "arraybuffer",
          onDownloadProgress: (evt) => {
            this.percentage = parseInt((evt.loaded / evt.total) * 100);
          },
        })
        .then((res) => {
          this.documentUrl = window.URL.createObjectURL(new Blob([res.data]));
        })
        .catch((error) => {
          this.$dialog({ title: "错误", message: error.message });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //加载文档
    loadDocument() {
      if (!this.$store.getters.isLogin) {
        return;
      }
      if (this.$refs.viewer) {
        this.$refs.viewer.open(this.documentGuid, this.documentUrl, this.documentPassword);
      }
    },
    //添加访问商品记录
    addVisitProductRecord() {
      return Manager.addVisitProductRecord(this.documentName);
    },
    //添加访问学校记录
    addVisitSchoolRecord() {
      Manager.addVisitSchoolRecord({
        DocumentGuid: this.documentGuid,
        RecordModule: 4,
      });
    },
    //首页
    onHome() {
      this.$router.push({ name: "Home" });
    },
    //点击在线支付
    onPay() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPreviewUrl(this.$store.state.proxyCode, this.documentGuid);
        // window.location.href = Manager.goDocumentPaymentUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    },
    //点击联系客服激活
    onRegist() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPreviewUrl(this.$store.state.proxyCode, this.documentGuid);
        // window.location.href = Manager.goDocumentRegistUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    },
    //点击打印
    onPrint() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        window.location.href = Manager.goDocumentPrintUrl(this.$store.state.proxyCode, this.documentGuid);
      }
    },
    //点击下载
    onDownload() {
      if (!this.$store.getters.isLogin) {
        this.$loginDialog.show(true);
      } else {
        Tools.downloadUrl(this.documentUrl, this.documentName + '.pdf');
      }
    },
    //点击相关资料
    onRelated() {
      this.popup = true;
    },
  },
  async mounted() {
    console.log(window.location.hostname, '域名');
    //通过判断域名、是否已经登录、是否传递token，来判断该链接是否是分享访问。
    if (window.location.hostname == 'handebook.qingstudy.cn' && !this.$store.getters.isLogin && !this.$route.query.token) {
      this.$dialog({ title: "提示", message: '为了方便同步登录信息，请从网站跳转观看，该域名暂不支持分享链接方式访问。', });
      return;
    }

    try {
      await this.getRoutParams();
      await this.getDocumentPurchase();
      await this.getDocumentInfo();
      await this.getDocumentPassword();
      await this.getDocumentUrl();
      this.loadDocument();
      this.addVisitProductRecord();
      this.addVisitSchoolRecord();
    } catch (error) {
      this.$dialog({ title: "错误", message: error.message });
    }
  }
};
</script>
<style scoped>
.view-box {
  height: 100%;
}

.document-box {
  width: 100%;
  height: calc(100% - 100px);
}

.document-viewer {
  height: 100%;
}

.bar-box {
  height: 50px;
}

.van-overlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.down-progress {
  width: 90%;
}

.down-describe {
  margin-top: 20px;
  color: white;
  font-size: 14px;
}
</style>